<template>
  <base-card class="provider-price">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
          <v-autocomplete
            v-model="streetName"
            :items="streetCityList"
            :loading="isLoading"
            :search-input.sync="searchStreet"
            class="mb-4"
            item-text="value"
            item-value="value"
            :placeholder="`${$t('common.pleaseInput')} ${$t(
                'address.streetName'
              ).toLowerCase()}`"
            :append-icon="null"
            prepend-icon="mdi-map-marker"
            return-object
            hide-details
            clearable
            :filter="() => true"
            v-if="searchStep === 1"
          >
            <template v-slot:label>
              <span>
                {{ $t("address.streetName") }}*
              </span>
            </template>
            <template v-slot:selection="data">
              <span>
                {{ data.item.value }}
              </span>
            </template>
            <template v-slot:item="data">
              <v-icon class="pr-3">mdi-map-marker</v-icon> {{ data.item.value }}
            </template>
          </v-autocomplete>

          <template v-else>
            <v-autocomplete
              v-model="streetNumber"
              :items="fullAddressList"
              :loading="isLoading"
              :search-input.sync="searchFullAddress"
              item-text="value"
              item-value="value"
              :placeholder="formatStreetCity(address)"
              :append-icon="null"
              prepend-icon="mdi-map-marker"
              return-object
              hide-details
              clearable
              @click:clear="resetData"
            >
              <template v-slot:label>
                <span>
                  {{ $t("address.streetNumber") }}
                *</span>
              </template>
              <template v-slot:selection="data">
                <span>
                  {{ address.street }} {{ data.item.value }},
                  {{ util.formatPostalCode(address.postalCode) }} {{ address.locality }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-icon class="pr-3">mdi-map-marker</v-icon> {{ data.item.value }}
              </template>
            </v-autocomplete>
          </template>

          <v-radio-group
            v-model="networkCategory"
            row
            hide-details
            v-if="isShowSearchType"
          >
            <div class="d-flex justify-center w-full">
              <v-radio
                value="commercial"
                class="mr-16"
                hide-details
              >
                <template #label>
                  <span class="text-subtitle-1">
                    {{ $t('price.business') }}
                  </span>
                </template>
              </v-radio>
              <v-radio
                value="residential"
                class="ml-16"
                hide-details
              >
                <template #label>
                  <span class="text-subtitle-1">
                    {{ $t('price.residential') }}
                  </span>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-col>
      </v-row>

      <template v-if="isFetchedPrice">
        <v-row v-if="availableAccesses.length > 0">
          <v-col cols="12" sm="8" offset-sm="2">
            <div class="elevation-2 pa-4">
              <div class="text-center text-18 font-weight-bold mb-3">
                {{ $t("access.availableAccesses") }}
              </div>
              <v-row class="px-4 pb-2">
                <v-col cols="3" class="py-0 text-16">
                  {{ $t("order.accessId") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("price.network") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("order.mduApartmentNumber") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("order.mduDistinguisher") }}
                </v-col>
              </v-row>

              <template>
                <v-radio-group
                  v-model="accessId"
                  column
                  hide-details
                  class="mt-0 pt-0"
                  @change="chooseAccess"
                >
                  <v-row
                    v-for="(item, index) in availableAccesses"
                    :key="`${index}-access`"
                    class="px-4 pb-2"
                  >
                    <v-col cols="3" class="py-0">
                      <v-radio
                        :value="item.accessId"
                        :label="item.accessId"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ $t(`access.${item.network}`) }}
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ item.mduApartmentNumber }}
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ item.mduDistinguisher }}
                    </v-col>
                  </v-row>
                </v-radio-group>
              </template>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="stokabPoints.length > 0">
          <v-col cols="12" sm="8" offset-sm="2">
            <div class="elevation-2 pa-4">
              <div class="text-center text-18 font-weight-bold mb-3">
                {{ $t("access.stokabPoints") }}
              </div>
              <v-row class="px-4 pb-2">
                <v-col cols="3" class="py-0 text-16">
                  {{ $t("access.pointName") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("common.type") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("access.state") }}
                </v-col>

                <v-col cols="3" class="py-0 text-16">
                  {{ $t("access.fiberStatus") }}
                </v-col>
              </v-row>

              <template>
                <v-radio-group
                  v-model="stokabPointId"
                  column
                  hide-details
                  class="mt-0 pt-0"
                  @change="chooseStokabPoint"
                >
                  <v-row
                    v-for="(item, index) in stokabPoints"
                    :key="`${index}-point`"
                    class="px-4 pb-2"
                  >
                    <v-col cols="3" class="py-0">
                      <v-radio
                        :value="item.pointId"
                        :label="item.pointId"
                        hide-details
                      />
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ util.getStokabTypeName(item.type) }}
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ item.state }}
                    </v-col>

                    <v-col cols="3" class="py-0">
                      {{ item.fiberStatus }}
                    </v-col>
                  </v-row>
                </v-radio-group>
              </template>
            </div>
          </v-col>
        </v-row>

        <div>
          <v-data-table
            :headers="headers"
            :items="availableProducts"
            :items-per-page="-1"
            hide-default-footer
            class="elevation-2 mt-4 pa-4"
            v-if="isShowPrice"
          >
            <template v-slot:top>
              <div class="text-center text-18 font-weight-bold">
                {{ $t("network.availableProducts") }}
              </div>
            </template>
            <template v-slot:item.start="{item}">
              {{ item.start }} SEK
            </template>
            <template v-slot:item.price="{item}">
              {{ item.price }} SEK
            </template>
            <template v-slot:item.term="{item}">
              {{ item.term }} {{ $t("order.months") }}
            </template>
            <template v-slot:item.action="{item}">
              <div class="d-flex justify-space-between">
                <v-btn
                  rounded
                  small
                  color="primary"
                  class="px-4"
                  @click="orderPrice(item)"
                >
                  {{ $t("order.order") }}
                </v-btn>

                <v-btn
                  rounded
                  small
                  color="info"
                  class="px-4"
                  v-if="item.network === 'STOKAB'"
                  @click="requestManualStokabQuote(item)"
                >
                  {{ $t("price.manualPrice") }}
                </v-btn>
              </div>
            </template>
          </v-data-table>

          <template v-else-if="isShowManualOrder || (availableAccesses.length === 0 && stokabPoints.length === 0)">
            <v-row>
              <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
                <div class="text-center orange--text text--darken-4 text-18 font-weight-bold">
                  {{ $t("price.needInviestiageManually") }}<br>
                  {{ $t("price.checkSpeedsAndSubmit") }}
                </div>

                <SpeedSelector @change-speeds="changeSpeeds" />
              </v-col>
            </v-row>
          </template>
        </div>
      </template>
    </v-card-text>

    <v-card-actions class="justify-center pb-4" v-if="isFetchedPrice && availableProducts.length === 0 && (isShowManualOrder || (availableAccesses.length === 0 && stokabPoints.length === 0))">
      <v-btn
        rounded
        color="primary"
        :disabled="isLoading || !isSpeedSelected"
        class="px-8 mr-6"
        @click="requestPrice('general')"
      >
        {{ $t("common.submit") }}
      </v-btn>
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as _ from 'lodash'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import SpeedSelector from './SpeedSelector'

  export default {
    metaInfo: {
      title: 'Search Network',
    },

    components: {
      SpeedSelector,
    },

    data () {
      return {
        isLoading: false,
        address: {
          street: '',
          locality: '',
          postalCode: '',
          streetNumber: '',
          streetLetter: '',
        },
        networkCategory: 'commercial',
        streetName: '',
        searchStreet: null,
        streetCityList: [],

        streetNumber: '',
        searchFullAddress: null,
        fullAddressList: [],

        searchStep: 1,

        price: null,
        selectedSpeeds: {
          basicChkList: [],
          plusChkList: [],
          premiumChkList: [],
        },
        headers: [
          { value: 'action', text: 'Action', width: 120 },
          { value: 'network', text: this.$t('price.network') },
          { value: 'type', text: this.$t('price.type') },
          { value: 'speed', text: this.$t('price.speed') },
          { value: 'start', text: this.$t('price.start') },
          { value: 'price', text: this.$t('price.price') },
          { value: 'term', text: this.$t('price.term') },
          { value: 'serviceName', text: this.$t('network.serviceName') },
          { value: 'nodeType', text: this.$t('price.nodeType') },
        ],
        accessId: '',
        stokabPointId: '',
        stokabProduct: null,
        availableProducts: [],
        isShowManualOrder: false,
        availableServices: [],

        util: util,
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
      }),

      isFetchedPrice() {
        return !!this.price
      },

      isStokabAvailable() {
        return this.price && this.price.stokab
      },

      isShowPrice() {
        return this.availableProducts.length > 0
      },

      stokabPoints() {
        return this.price?.stokab?.availablePoints || []
      },

      availableSollentunaAccesses() {
        if (!this.price || !this.price.sollentuna || !this.price.sollentuna.rows) {
          return []
        }

        return this.price.sollentuna.rows.map(item => ({
          ...item,
          network: 'sollentuna',
        }))
      },

      availableZitiusAccesses() {
        if (!this.price || !this.price.zitius || !this.price.zitius.rows) {
          return []
        }

        return this.price.zitius.rows.map(item => ({
          ...item,
          network: 'zitius',
        }))
      },

      availableOpeninfraAccesses() {
        if (!this.price || !this.price.openinfra || !this.price.openinfra.rows) {
          return []
        }

        return this.price.openinfra.rows.map(item => ({
          ...item,
          network: 'openinfra',
        }))
      },

      availableOpenuniverseAccesses() {
        if (!this.price || !this.price.openuniverse || !this.price.openuniverse.rows) {
          return []
        }

        return this.price.openuniverse.rows.map(item => ({
          ...item,
          network: 'openuniverse',
        }))
      },

      availableIpOnlyAccesses() {
        if (!this.price || !this.price.ipOnly || !this.price.ipOnly.rows) {
          return []
        }

        return this.price.ipOnly.rows.map(item => ({
          ...item,
          network: 'ipOnly',
        }))
      },

      isShowSearchType() {
        if (!this.currentUser.partner) {
          return true
        }

        if (this.currentUser.partner.searchType === 'both') {
          return true
        }

        return false
      },

      isSpeedSelected() {
        return (
          this.selectedSpeeds.basicChkList.length > 0 ||
          this.selectedSpeeds.plusChkList.length > 0 ||
          this.selectedSpeeds.premiumChkList.length > 0
        )
      },

      availableAccesses() {
        return [
          ...this.availableSollentunaAccesses,
          ...this.availableOpeninfraAccesses,
          ...this.availableOpenuniverseAccesses,
          ...this.availableIpOnlyAccesses,
          ...this.availableZitiusAccesses,
        ]
      },
    },

    watch: {
      searchStreet: {
        handler: _.debounce(function (value) {
          if (this.isLoading || !value || value.length === 0) {
            this.streetCityList = []
            return
          }

          this.getSuggestionListWithStreetAndCity(value)
        }, 500),
      },

      searchFullAddress (value) {
        this.getSuggestionListWithFullAddress(value)
      },

      streetName (value) {
        if (!value) {
          this.streetCityList = []
          return
        }

        this.chooseStreetAndCity(value)
      },

      streetNumber (value) {
        if (!value) {
          this.fullAddressList = []

          if (this.searchStep === 2) {
            this.getSuggestionListWithFullAddress(value)
          }
          return
        }

        this.chooseFullAddress(value)
      },

      networkCategory() {
        if (!this.isFetchedPrice || !this.accessId) {
          return
        }

        this.chooseAccess()
      },

      currentUser: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value || !value.partner) {
            return
          }

          this.networkCategory = value.partner.searchType
          if (value.partner.searchType === 'commercial') {
            this.networkCategory = 'commercial'
          } else if (value.partner.searchType === 'residential') {
            this.networkCategory = 'residential'
          } else {
            this.networkCategory = 'commercial'
          }
        },
      },
    },

    methods: {
      ...mapActions({
        getSuggestionAddress: 'getSuggestionAddress',
        getAccessAndProducts: 'getAccessAndProducts',
        addRequestPrice: 'addRequestPrice',
        setServiceAddress: 'setServiceAddress',
        setNetworkInfo: 'setNetworkInfo',
        setStokabProductInfo: 'setStokabProductInfo',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        setSelectedAccess: 'setSelectedAccess',
        getAccessDetail: 'getAccessDetail',
        setAvailableService: 'setAvailableService',
        getStokabNetworks: 'getStokabNetworks',
        setSelectedStokabPoint: 'setSelectedStokabPoint',
      }),

      async fetchProviderPrice() {
        this.price = null
        this.isShowManualOrder = false
        this.accessId = ''

        this.selectedSpeeds = {
          basicChkList: [],
          plusChkList: [],
          premiumChkList: [],
        }

        this.setLoading(true)
        this.setLoadingText(this.$t('network.loadingProducts'))

        try {
          const payload = {
            street: this.address.street,
            streetNumber: this.address.streetNumber,
            streetLetter: this.address.streetLetter,
            locality: this.address.locality,
            postalCode: this.address.postalCode,
            networkCategory: this.networkCategory,
          }

          this.price = await this.getAccessAndProducts(payload)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async getSuggestionListWithStreetAndCity(queryString) {
        const payload = {
          query: queryString,
          columns: 'street,locality',
        }

        this.isLoading = true
        try {
          const response = await this.getSuggestionAddress(payload)
          this.streetCityList = response.rows.map(item => ({
            value: this.formatStreetCity(item),
            locality: item.locality,
            street: item.street,
          }))
        } catch (error) {
          this.streetCityList = []
        }
        this.isLoading = false
      },

      async getSuggestionListWithFullAddress(queryString, cb) {
        const payload = {
          query: this.address.street,
          locality: this.address.locality,
          streetNumber: queryString,
          columns: 'street,locality, postalcode,street_number,letter',
        }

        this.isLoading = true
        try {
          const response = await this.getSuggestionAddress(payload)

          this.fullAddressList = response.rows.map(item => ({
            ...item,
            value: this.formatStreetNumber(item),
          }))
        } catch (error) {
          this.fullAddressList = []
        }
        this.isLoading = false
      },

      formatStreetCity(result) {
        return `${result.street}, ${result.locality}`
      },

      formatStreetNumber(result) {
        return result.letter
          ? `${result.street_number} ${result.letter}`
          : result.street_number
      },

      chooseStreetAndCity(result) {
        this.price = null
        this.accessId = ''
        this.isShowManualOrder = false
        this.address = {
          street: result.street,
          locality: result.locality,
        }

        this.searchStep = 2

        this.getSuggestionListWithFullAddress('')
      },

      chooseFullAddress(result) {
        this.address = {
          ...this.address,
          postalCode: result.postalcode,
          streetNumber: result.street_number,
          streetLetter: result.letter,
        }

        this.fetchProviderPrice()
      },

      async requestPrice(type = 'general', product = null) {
        const postData = {
          street: this.address.street,
          number: this.address.streetNumber,
          littera: this.address.streetLetter,
          city: this.address.locality,
          postalCode: this.address.postalCode,
          basic: this.selectedSpeeds.basicChkList,
          plus: this.selectedSpeeds.plusChkList,
          premium: this.selectedSpeeds.premiumChkList,
          product,
          type,
        }

        this.isLoading = true
        try {
          await this.addRequestPrice(postData)
          this.isLoading = false

          showSuccessDialog(this.$t('price.requestPriceSuccess'))

          this.resetData()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.isLoading = false
      },

      requestManualStokabQuote(row) {
        this.selectedSpeeds = {
          basicChkList: [row.upSpeed],
          plusChkList: [],
          premiumChkList: [],
        }

        const contractPeriodYears = Math.ceil(row.term / 12)
        this.requestPrice('stokab', {
          ...this.stokabProduct,
          contractPeriodYears,
          networkId: row.id,
        })
      },

      orderPrice(row) {
        this.setNetworkInfo(row)
        this.setServiceAddress(this.address)
        this.setStokabProductInfo(this.stokabProduct)

        const selectedService = this.availableServices.find(item => item.service === row.serviceName || item.service === row.serviceAlias)
        this.setAvailableService(selectedService)

        const selectedPoint = this.stokabPoints.find(item => item.pointId === this.stokabPointId)

        this.$router.push(`/order-price?business=${this.networkCategory}`)
      },

      changeSpeeds(selectedSpeeds) {
        this.selectedSpeeds = selectedSpeeds
      },

      chooseAccess() {
        this.isShowManualOrder = false
        this.availableProducts = []
        this.stokabPointId = ''
        this.stokabProduct = null
        this.setSelectedStokabPoint(null)
        this.setStokabProductInfo(null)

        const access = this.availableAccesses.find(item => item.accessId === this.accessId)
        this.setSelectedAccess(access)
        this.loadAccessDetail(access)
      },

      async loadAccessDetail(access) {
        this.setLoading(true)
        this.setLoadingText(this.$t('network.loadingProducts'))

        try {
          const payload = {
            type: access.network,
            accessId: access.accessId,
            networkCategory: this.networkCategory,
          }

          const response = await this.getAccessDetail(payload)

          if (!response || !response.services) {
            this.isShowManualOrder = true
            this.setLoading(false)
            return
          }

          this.availableServices = response.services.filter(item => item.connection !== 'NO' || item.available !== 'NO')
          this.availableProducts = response.products || []

          if (this.availableProducts.length === 0) {
            this.isShowManualOrder = true
            this.setLoading(false)
            return
          }
        } catch (error) {
          this.isShowManualOrder = true

          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      async chooseStokabPoint() {
        this.setLoading(true)
        this.setLoadingText(this.$t('network.loadingProducts'))

        this.accessId = ''
        this.stokabProduct = null
        this.availableProducts = []

        this.setSelectedAccess(null)
        this.setSelectedStokabPoint(null)

        try {
          const pointItem = this.stokabPoints.find(item => item.pointId === this.stokabPointId)

          const payload = {
            pointId: pointItem.pointId,
            type: pointItem.type,
            networkCategory: this.networkCategory,
          }

          const response = await this.getStokabNetworks(payload)

          this.availableProducts = response.productList
          this.stokabProduct = response.product

          this.setSelectedStokabPoint(pointItem)
        } catch (error) {
          this.isShowManualOrder = true

          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },

      resetData() {
        this.price = null
        this.accessId = ''
        this.availableProducts = []
        this.isShowManualOrder = false
        this.isLoading = false
        this.searchStep = 1
        this.address = {
          street: '',
          locality: '',
          postalCode: '',
          streetNumber: '',
          streetLetter: '',
        }
        this.streetName = ''
        this.streetNumber = ''
        this.$emit('set-search-result', null)
      },
    },
  }
</script>
