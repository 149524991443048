<template>
  <div>
    <div class="text-16 font-weight-bold mt-5">
      {{ $t("access.basicNetworkTypeComment") }}
    </div>
    <div class="d-flex justify-space-between">
      <v-checkbox
        v-model="basicChkList"
        :label="$t('price.networkSpeed', { speed: 100 })"
        :value="100"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="basicChkList"
        :label="$t('price.networkSpeed', { speed: 250 })"
        :value="250"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="basicChkList"
        :label="$t('price.networkSpeed', { speed: 500 })"
        :value="500"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="basicChkList"
        :label="$t('price.networkSpeed', { speed: 1000 })"
        :value="1000"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="basicChkList"
        :label="$t('price.networkSpeed', { speed: 10000 })"
        :value="10000"
        hide-details
        class="mt-0"
      />
    </div>

    <div class="text-16 font-weight-bold mt-5">
      {{ $t("access.plusNetworkTypeComment") }}
    </div>
    <div class="d-flex justify-space-between">
      <v-checkbox
        v-model="plusChkList"
        :label="$t('price.networkSpeed', { speed: 100 })"
        :value="100"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="plusChkList"
        :label="$t('price.networkSpeed', { speed: 250 })"
        :value="250"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="plusChkList"
        :label="$t('price.networkSpeed', { speed: 500 })"
        :value="500"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="plusChkList"
        :label="$t('price.networkSpeed', { speed: 1000 })"
        :value="1000"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="plusChkList"
        :label="$t('price.networkSpeed', { speed: 10000 })"
        :value="10000"
        hide-details
        class="mt-0"
      />
    </div>

    <div class="text-16 font-weight-bold mt-5">
      {{ $t("access.premiumNetworkTypeComment") }}
    </div>
    <div class="d-flex justify-space-between">
      <v-checkbox
        v-model="premiumChkList"
        :label="$t('price.networkSpeed', { speed: 100 })"
        :value="100"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="premiumChkList"
        :label="$t('price.networkSpeed', { speed: 250 })"
        :value="250"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="premiumChkList"
        :label="$t('price.networkSpeed', { speed: 500 })"
        :value="500"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="premiumChkList"
        :label="$t('price.networkSpeed', { speed: 1000 })"
        :value="1000"
        hide-details
        class="mt-0"
      />

      <v-checkbox
        v-model="premiumChkList"
        :label="$t('price.networkSpeed', { speed: 10000 })"
        :value="10000"
        hide-details
        class="mt-0"
      />
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        basicChkList: [],
        plusChkList: [],
        premiumChkList: [],
      }
    },

    watch: {
      basicChkList: {
        deep: true,
        handler () {
          this.changeSpeeds()
        },
      },
      plusChkList: {
        deep: true,
        handler () {
          this.changeSpeeds()
        },
      },
      premiumChkList: {
        deep: true,
        handler () {
          this.changeSpeeds()
        },
      },
    },

    methods: {
      changeSpeeds () {
        const data = {
          basicChkList: this.basicChkList,
          plusChkList: this.plusChkList,
          premiumChkList: this.premiumChkList,
        }

        this.$emit('change-speeds', data)
      },
    },
  }
</script>
